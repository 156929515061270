@use 'src/styles/mobile/colors.scss';
@use 'src/styles/web/common';
@import '@/styles/mobile/common.scss';

.faqsWrapper {
    @extend .Section;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F8F9FD;
}

.faqsTitleCont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: size(70) size(0) size(15) size(0);
}

.faqTitleChinese {
    width: size(168);
    height: size(56);
}

.faqTitleEnglish {
    width: size(100);
    height: size(50);
}

.faqSubtitle {
    height: size(25);
    width: size(41);
    font-style: normal;
    font-weight: 400;
    font-size: size(16);
    line-height: size(38);
    text-transform: capitalize;
    color: #071325;
    opacity: 0.4;
    transform: translate(size(0), size(-12));
}

.faqsContent {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    transform: translate(size(0), size(-4));
    height: size(500);
}

.faqsContentContainer {
    height: size(436);
    overflow: scroll;
}

.faqsContent::-webkit-scrollbar {
    display: none;
}

.faqsContentContainer::-webkit-scrollbar {
    display: none;
}

.faqsBoxCont {
    display: flex;
    flex-direction: column;
    padding: size(14);
    width: size(351);
    background: linear-gradient(181.9deg, rgba(255, 255, 255, 0.8526) 62.63%, rgba(255, 255, 255, 0.98) 93.18%);
    border-radius: size(9);
    margin: size(12.72) size(0) size(-2) size(0);
}

.faqsBoxCont:nth-child(1) {
    margin: size(0) size(0) size(-2) size(0);
}

.faqsBoxCont:last-child {
    margin: size(12.72) size(0) size(0) size(0);
}

.faqsQuesCont {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.faqsIcon {
    width: size(15);
    height: size(14);
}

.faqsQuestion {
    width: size(301);
    min-height: size(28);
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 500;
    font-size: size(16);
    line-height: size(28);
    color: #071325;
    padding: size(0) size(0) size(0) size(4);
}

.faqsAnswer {
    width: 323px;
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 400;
    font-size: size(12);
    line-height: size(24);
    color: #071325;
    opacity: 0.6;
}

.footerCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: size(351);
    padding: size(10) size(0) size(10) size(0);
    background-color: #F8F9FD;
    position: absolute;
    left: size(0);
    bottom: size(0);
}

.footerCompYear {
    height: size(14);    
    font-style: normal;
    font-weight: 400;
    font-size: size(10);
    line-height: size(14);
    letter-spacing: 0.09em;
    text-transform: capitalize;
    color: #071325;
    margin: size(4) size(0) size(3) size(0);
}

.footerContHr {
    height: size(14);
    font-style: normal;
    font-weight: 400;
    font-size: size(10);
    line-height: size(14);
    letter-spacing: 0.09em;
    text-transform: capitalize;
    color: #071325;
    margin: size(3) size(0) size(4) size(0);
}