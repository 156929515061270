@use 'src/styles/web/common';

.faqWrapper{
    @extend .Section;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F8FD;
}

.faqs-container {
    display: flex;
    flex-direction: row;

    width: 1169px;
    height: 631px;
    margin-left: -5px;
}

.faqs-title {
    width: 345px;
    // height: 826px;
    position: relative;

    .faqs-title-en {
        width: 460px;
        height: 76px;
        transform: translate(-68px, 12px);
    }

    .faqs-title-chinese {
        height: 76px;
        width: 228px;
        transform: translate(-69px, 8px);
    }

    .faqs-title-sub {
        width: 76px;
        height: 42px;
        font-family: 'PingFangSC';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 42px;
        color: #071325;
        opacity: 0.2;
        transform: translate(-58px, -7px);
    }

    .faqs-ques-bg {
        width: 820px;
        height: 434px;
        border-radius: 8px;
        position: absolute;
        top: 31px;
        left: 229px;
    }
    
    .faqs-side-bg {
    
        height: 650px;
        width: 650px;
        position: absolute;
        top: 41px;
        left: -330px;
    }
}

.faqs-content {
    width: 824px;
    height: 631px;
    padding-top: 70px;
    display: flex;
    position: relative;
}

.faqs-sidebar {
    height: 550px;
    width: 500px;
    margin: 0px 25px 25px 25px;
}

.faqs-question-container {
    height: 562px;
    width: 843px;
    margin-left: -135px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 9px;
    transform: translate(67px, 6px);
    background: linear-gradient(181.9deg, rgba(255, 255, 255, 0.8526) 62.63%, rgba(255, 255, 255, 0.98) 93.18%);

    .faqs-qa {
        width: 785px;
        display: flex;
        flex-direction: column;
        margin: 0px 20px 0px 20px;
        padding-bottom: 15px;
        cursor: pointer;        

        .faqs-ques {
            display: flex;
            flex-direction: row;           

            span {
                display: flex;
                align-items: left;
                width: 521px;
                height: 28px;
                font-family: 'PingFangSC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.04em;
                color: #071325;
                padding-bottom: 15px;
            }

            span:hover {
                // color: #01CA69 !important;
            }

            .bullet-icon {
                width: 7px;
                height: 7px;
                transform: translate(0px, 12px);
                margin-right: 10px;
            }
        }

        .faqs-ans {
            width: 828px;
            display: flex;
            font-family: 'PingFangSC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;            
            letter-spacing: 0.04em;
            color: #000000;
            opacity: 0.6;
            background: rgba(238, 253, 249, 0.68);
            transform: translate(-29px, 0px);

            span {
                padding: 10px 75px 10px 55px;
            }
        }
    }
    
    .faqs-qa:nth-child(1),
    .faqs-qa:nth-child(2),
    .faqs-qa:nth-child(3),
    .faqs-qa:nth-child(4),
    .faqs-qa:nth-child(5) {
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    }
}

.faqs-img-cont {
    height: 432px;
    width: 432px;
    position: relative;

    .faqs-img {
        height: 432px;
        width: 432px;
        position: absolute;
        top: 180px;
        left: -101px;
    }
}
