@use 'src/styles/mobile/colors.scss';
@import '@/styles/mobile/common.scss';

.headerToggle {
  width: size(82);
  height: size(30);
  margin-right: size(5);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  > span {
    color: #071325;
  }

  .mobileActive {
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    // color: #13DDAD;
  }

  .mobile {
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    // color: #071325;
  }
}