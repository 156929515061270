.header {    
   display: flex;
   align-items: center;
   justify-content: center;

   width: 100vw;
   height: 68px;
   position: fixed;
   z-index: 2;
   background-color: #FFF;
   
   .headerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 1306px;
   }

   .headerContainerSafari {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 12px 0 13px 0;
      grid-gap: 1126px;
   }
   .headerLogo {
      width: 138px;
      transform: translateY(-2px);
   }
   
   .headerSwitch {
      width: 88px;
      height: 32px;
      cursor: pointer;
   }
}