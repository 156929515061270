@import url('@assets/web/font/font.css');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: PingFangSC;
}

html, body, #root {
  width: 100vw;
  height: 100vh;
}