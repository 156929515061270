.switch {
   width: 88px;
   height: 32px;
   position: relative;
   display: inline-block;
   // margin: 0 5px;
}

.check-toggle {
   position: absolute;
   margin-left: -9999px;
   visibility: hidden;
}

.check-toggle + label {
   display: block;
   position: relative;
   cursor: pointer;
   outline: none;
   user-select: none;
}

.switch > span {
   position: absolute;
   top: 8px;
   pointer-events: none;
   font-family: 'PingFangSC';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   text-transform: uppercase;
   text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
   width: 50%;
   text-align: center;
}

.switch > span.off {
   right: -4px;
   // padding-right: 0px;
   color: #071325;
}

input.check-toggle-round-flat:checked ~ .off {
   color: #FFFFFF;
}

.switch > span.on {
   left: 0;
   padding-left: 8px;
   color: #FFFFFF;
}

input.check-toggle-round-flat:checked ~ .on {
   color: #071325;
}

input.check-toggle-round-flat + label {
   padding: 2px;
   width: 98px;
   height: 32px;
   background-color: rgba(252, 239, 239, 0.32);
   border: 1px solid #FFFFFF;
   box-shadow: inset 0px 0px 1px rgba(109, 205, 170, 0.5), inset 0px 0px 8px rgba(190, 227, 210, 0.5);
   border-radius: 50px;
}

input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
   display: block;
   position: absolute;
   content: "";
}

input.check-toggle-round-flat + label:before {
   top: 2px;
   left: 2px;
   bottom: 2px;
   right: 2px;
   background-color: #F36F25;
   background-color: rgba(252, 239, 239, 0.32);
   border-radius: 50px;
}

input.check-toggle-round-flat + label:after {
   top: 2px;
   left: 2px;
   // bottom: 4px;
   width: 48px;
   height: 26px;
   background-color: #13DDAD;
   border-radius: 50px;
   transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label:after {
   margin-left: 44px;
}


