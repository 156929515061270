@use 'src/styles/web/common';

.contactWrapper {
    @extend .Section;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F8FD;

    .contactCont {
        display: flex;
        flex-direction: row;
    
        width: 1315px;
        .contactLeft {
            width: 317px;
            min-height: 530;
            position: relative;
            .contactTitle {
                width: 228px;
                height: 76px;
            }
            .contactTitleEN {
                width: 460px;
                height: 76px;
            }
            .contactSubtitle {
                margin-left: 10px;
                font-family: 'PingFangSC';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 10px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                
                color: #071325;
                opacity: 0.2;
            }
            .contactLeftImage {
                width: 650px;
                height: 650px;
                position: absolute;
                top: 34px;
                right: -72px;
            }
        }
        .contactRight {
            position: relative;
            
            .contactBgItem1 {
                position: absolute;
                width: 242px;
                height: 258px;
                top: 365px;
                right: -102px;
            }
            .contactSubCont {
                width: 998px;
                height: 530px;
                padding: 49px 0px 0 199px;
    
                background: linear-gradient(2.54deg, rgba(255, 255, 255, 0) 6.72%, 
                    rgba(186, 198, 227, 0.252) 140.78%), 
                    linear-gradient(180deg, rgba(248, 249, 253, 0.5382) 0%,
                    rgba(248, 249, 253, 0.78) 100%);
                backdrop-filter: blur(11.5px);
                
                border-radius: 8px;

                .contactBgItem2 {
                    position: absolute;
                    width: 422px;
                    height: 422px;
                    bottom: -58px;
                    left: -148px;
                }

                .itemCont{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-content: space-between;
                    position: relative;
                    
                    width: 666px;
                    height: 422px;
                    z-index: 2;
                    
                    filter: drop-shadow(0px 20px 25px #E2F6F3);
                    
                    .contactItem {
                        width: 292px;
                        height: 190px;

                        background-size: cover;
                        border-radius: 8px;
                        border: 1px solid #F8FAFF;
                        background: linear-gradient(151.67deg, rgba(255, 255, 255, 0.0098) 21.2%, rgba(255, 255, 255, 0.6762) 79.25%), #F8FAFF;
                        img {
                            display: block;
                            margin: 50px auto 45px;
                        }

                        p {
                            font-family: PingFangSC;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 28px;
                            text-align: center;
    
                            letter-spacing: 0.04em;
                        }
                    }
                }
            }
        }
    }
}