@use 'src/styles/mobile/colors.scss';
@use 'src/styles/web/common';
@import '@/styles/mobile/common.scss';

.bonusWrapperMobile {
    // @extend .Section;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F6F8FD;

    .bonusCont {
        display: flex;
        flex-direction: column;
        align-items: center;

        .bonusHeader {
            display: flex;
            flex-direction: column;
            position: relative;

            width: size(274);
            min-height: size(128);
            margin-bottom: size(14);

            .titleCont {
                display: flex;
                flex-direction: column;
                align-items: center;

                height: max-content;
                // margin-bottom: 20px;
                .bonusTitle {
                    width: size(168);
                    height: size(56);
                }
                .bonusTitleEN {
                    width: size(182);
                    height: size(50);
                }
                .bonusSubtitle {
                    min-height: size(16);
                    position: relative;
                    
                    font-family: 'PingFangSC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: size(16);
                    // line-height: size(22);
                    text-align: start;
                    
                    text-transform: uppercase;
                    
                    color: #071325;
                    opacity: 0.2;
                    z-index: 1;
                }
            }
            .bonusDesc {
                width: size(274);
                min-height: size(44);

                font-family: 'PingFangSC';
                font-style: normal;
                font-weight: 400;
                font-size: size(12);
                line-height: size(22);
                text-align: center;

                color: #071325;
                opacity: 0.6;
            }
        }
        .itemCont {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // align-content: center;
            justify-content: space-between;
            position: relative;

            width: size(351);
            min-height: size(660);

            .bonusItem {
                display: flex;
                flex-direction: column;
                align-items: center;

                width: size(169);
                min-height: size(209);
                padding-inline: size(20);
                box-shadow: 0px 2px 4px #E9ECF7;
                margin-top: size(10);
                border: size(1) solid #ffffff;
                border-radius: size(8);
                background: linear-gradient(180deg, rgba(248, 249, 253, 0.5382) 0%, rgba(248, 249, 253, 0.78) 100%), linear-gradient(169.09deg, rgba(255, 255, 255, 0.88) 32.75%, rgba(255, 255, 255, 0.704) 98.44%);

                .bonusIcon {
                    width: size(90);
                    height: size(90);
                    margin-bottom: size(1);
                    
                    background-size: cover;
                }

                .bonusText{
                    display: flex;
                    flex-direction: column;
                    .bonusType {
                        // height: 28px;
                        margin-bottom: size(5);

                        font-family: 'PingFangSC';
                        font-style: normal;
                        font-weight: 600;
                        font-size: size(16);
                        line-height: size(24);
                        
                        text-align: center;
                        letter-spacing: 0.09em;
                        color: #071325;
                    }
                    ul {
                        list-style-type: none;
                        li {
                            font-family: 'PingFangSC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: size(12);
                            line-height: size(22);
        
                            text-align: center;
                            letter-spacing: 0.09em;
        
                            color: #071325;
                            opacity: 0.5;
                        }
                    }
                }
            }
            .bonusItem:hover {
                border: size(1) solid #EAEBEE;
            }
            
            :nth-child(1) { .bonusIcon{
                background-image: url('../../../assets/mobile/bonus/salary_salary_ic_normal@2x.webp');
            } }
            :nth-child(2) { .bonusIcon{
                background-image: url('../../../assets/mobile/bonus/salary_leaves_ic_normal@2x.webp');
            } }
            :nth-child(3) { .bonusIcon{
                background-image: url('../../../assets/mobile/bonus/salary_food_ic_normal@2x.webp');
            } }
            :nth-child(4) { .bonusIcon{
                background-image: url('../../../assets/mobile/bonus/salary_transportation_ic_normal@2x.webp');
            } }
            :nth-child(5) { .bonusIcon{
                background-image: url('../../../assets/mobile/bonus/salary_holiday_ic_normal@2x.webp');
            } }
            :nth-child(6) { .bonusIcon{
                background-image: url('../../../assets/mobile/bonus/salary_team\ building_ic_normal@2x.webp');
            } }
        }
    }
}