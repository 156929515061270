@use "src/styles/mobile/colors.scss";
@use 'src/styles/web/common';
@import "@/styles/mobile/common.scss";

.oppMbContainer {
  // @extend .Section;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f9fd;
  padding: 0 size(16);
  // height: auto;
}

.oppMbCategoryItem{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  
  letter-spacing: 0.09em;
  
  /* 正文浅灰 */
  
  color: #071325;
  
  opacity: 0.5;
  background: #ECEFF3;
  border-radius: 3px;
  // padding: 2px 9px;
}

.oppMbSearchContainer {
  width: 100%;
  height: size(40);
//  max-height: size(20);
  background-color: #fff;
  border-radius: size(5);
  display: flex;
  justify-content: center;
}
.oppMbTitle{
    //  width: 168px;
    height: size(56);
   
// background-color: blue;

}
.oppMbTitleText{
  margin-top: size(30);
  margin-bottom: size(-30);
}
.oppMbHeader{
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.searchButtonMb {
  width: 10%;
  background: #ffffff;

  // background-color: #071325;
  // text-align: center;
  // padding-left: 21px;
  // padding-right: 12px;
  // color: #071325;
  border-radius: size(5) 0 0 size(5);
  // cursor: pointer;
  // font-size: 16px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  // text-align: center;
}
.searchTermMb {
  //    background-color: green;
  width: 90%;
  // border: 6px solid #ffffff;
  margin: size(6) size(6) size(6) 0;
  padding-right: size(4);
  padding-left: size(10);
  border: none;
  border-left: none;
  border-radius: size(5);
  background: #f2f5f9;
  color: #071325;
}


// .searchTermMb::-webkit-input-placeholder{
//   // padding-left: 10px;
// }



.oppMbSearchIcon {
  width: 100%;
}

.oppMbCardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: size(12);
}
.oppMbCard {
  // width: 100%;
  // min-height: 100%;
  // min-width: 1024px;
  //    padding: 15px;
  //    overflow: hidden;
  background: url("../../../assets/mobile/opportunities/Mask_group.webp");
  
  // background: linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.4)), url("../../../assets/mobile/opportunities/Mask_group.webp");



  // background-color: rgba(1, 1, 1, 0.4);
  min-height: size(90);
  border: size(1) solid #fff;

  background-size: cover;
  //    background-clip: border-box;
  background-repeat: no-repeat;

  margin-bottom: size(10);
  display: flex;
  flex-direction: column;
  padding: size(12);
  border-radius: size(8);
  // opacity: 0.6;
}






// .oppMbCard::before {
//   content: "";
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   opacity: 0.6;
// }

.oppMbCard:hover {
}
.oppMbCardHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
}
.oppMbCardName {
  font-family: 'PingFangSC';
 
  font-style: normal;
  font-weight: 400;
  font-size: size(16);
  line-height: size(28);
  /* identical to box height, or 175% */
  
  letter-spacing: 0.04em;
  
  color: #071325;
}

.modalMbTimeSec{
margin-top: size(2);
}

.cardMbPostTime {

  font-family: "PingFangSC";
  font-style: normal;
  font-weight: 400;
  font-size: size(12);
  line-height: size(28);
  /* identical to box height, or 233% */

  letter-spacing: 0.04em;

  color: #071325;

  opacity: 0.4;
}

.oppMbCardFooter {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.oppMbCardSalary {
  font-family: "PingFangSC";
  font-style: normal;
  font-weight: 600;
  font-size: size(20);
  line-height: size(28);
  /* identical to box height, or 140% */

  letter-spacing: 0.04em;

  color: #01ca69;
}

.oppMbCardHeadCount {
  font-family: "PingFangSC";
  font-style: normal;
  font-weight: 400;
  font-size: size(14);
  line-height: size(28);
  /* identical to box height, or 200% */

  letter-spacing: 0.04em;

  color: #071325;
}

.oppMbCardHeadCount span{
  color: #13DDAD;
}

.oppBtnContainer{
    margin-top: size(10);
    display: flex;
    justify-content: center;
    align-items: center;
}
.oppMoreBtn{
    border: size(1) solid #071325;
border-radius: size(6);
padding: size(8) size(59);
background: #f2f5f9;
font-family: 'PingFangSC';
font-style: normal;
font-weight: 400;
font-size: size(14);
}




.modalMbContainer {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    width: 100%;
    height: 100%; 
    background-color: #00000075;
    z-index: 1000;
    font-family: 'PingFangSC';
background-color: #fff;  
  
padding-bottom: size(40);
}
  // .modalMbWrapper::-webkit-scrollbar{
  //   display: none;
  // }
  .modalMbWrapper{
    // position: fixed;
  
    font-family: 'PingFangSC';
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);   
    background-color: #fff;

    
    padding-bottom: size(30);
  
  
  }
  
  .modalMbContent{
    display: flex;
    flex-direction: column;
    // margin-top: 20px;
    font-family: 'PingFangSC';
    // padding: 20px;
    padding:size(24);
  
  }
  
  .modalMbCloseBtn{
    position: absolute;
    top: size(14);
    left: size(20);
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65)
  }
  .modalMbHead{
 
  }
  .modalMbRequirement{
    // font-family: 'PingFangSC';
    // padding: 16px 24px;
  padding-bottom: size(40);
  padding-left: size(16);
  }
  .modalMbHeadTitle{
//    background-color: red;
    font-family: 'PingFangSC';
    display: flex;
    justify-content: center;
    line-height: size(28);
    // border-bottom: 1px solid #e8e8e8;
    color: #000000;
    font-weight: 400;
    font-size: size(18);
    padding: size(14)
  
  }
  .modalMbResponse{
    font-family: 'PingFangSC';
    font-size: size(16)
  }
  .modalMbContentTitle{
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 500;
    font-size: size(18);
    margin-bottom: size(8);
    /* identical to box height, or 156% */
    
    margin-left: size(-18);
    /* 标题色 */
    
    color: #071325;
    
    
  }
  .titleMbExtra{
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 400;
    font-size: size(16);
    line-height: size(38);
    /* identical to box height, or 238% */
    margin-bottom: size(-10);
    text-transform: uppercase;
    
    color: #071325;
    
    opacity: 0.2;
  
  }
  .titleMbText{
    font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: size(12);
  // line-height: 22px;
  /* identical to box height, or 183% */
  
  text-align: center;
  letter-spacing: 0.09em;
  
  color: #071325;
  
  opacity: 0.6;
  margin-bottom: size(16);
  margin-top: size(10);
  // background-color: green;
  }
  
  .modalMbTopContent{
    display: flex;
  justify-content: space-between;
    // padding-bottom: 24px;
padding-left: size(18);
padding-right: size(16);
padding-bottom: size(10);
    // font-size: 14px;
    // border-bottom: 1px solid #e8e8e8;
    // color: rgba(0, 0, 0, 0.65);
    border-bottom: size(1) solid rgba(7, 19, 37, 0.05)
    // opacity: 0.05;
  
  }
  .modalMbTopContentItem1{
// background-color: red;
  }
  .modalMbTopContentItem2{
    // background-color: red;
      }


      .modalMbTopContentName{
        font-family: 'PingFangSC';
        font-style: normal;
        font-weight: 500;
        font-size: size(24);
        line-height: size(28);
        /* identical to box height, or 117% */
        
        
        color: #071325;


      }

.modalMbTopContentSalary{
    font-family: 'PingFangSC';
font-style: normal;
font-weight: 600;
font-size: size(20);
line-height: size(28);
/* identical to box height, or 140% */

letter-spacing: 0.04em;

color: #01CA69;
}
.oppMbcloseBtn{
position: absolute;
right: size(28);
// top:10px;
// background-color: red;
display: flex;
margin-top: size(11);

// top:-100px;
// top:-10px;
// z-index: 1000;
}
.modalMbTopContentCount{
    
font-family: 'PingFangSC';
font-style: normal;
font-weight: 400;
font-size: size(14);
line-height: size(28);
/* identical to box height, or 200% */

letter-spacing: 0.04em;

color: #071325;
}
.modalMbTopContentCount span{
color: #13DDAD;

}
.modalMbTopContentTime{
    display: flex;
    justify-content: space-around;
    align-items: center;
font-family: 'PingFangSC';
font-style: normal;
font-weight: 400;
font-size: size(12);
line-height: size(28);
/* identical to box height, or 233% */


/* 标题色 */

color: #071325;

opacity: 0.4;
}

  .modalMbSal{
    padding-left: size(20);
  margin-left: size(20);
  }
  .modalMbList{
    list-style-type:decimal;
    font-size: size(14);

    // padding-left: 40px;


    font-style: normal;
font-weight: 400;
font-size: size(14);

/* or 200% */

letter-spacing: 0.09em;

/* 正文浅灰 */
line-height: size(28);
/* or 200% */


color: #071325;

opacity: 0.5;
  }

  .modalMbListContainer{
    margin-bottom: size(30);
  }

  .recomSection{
    width: 100%;
background-color: transparent
  }

  .recomItemContainer{
    display: flex;
    flex-wrap: wrap;
  }

  .recomTitle{
    font-family: 'PingFangSC';
font-style: normal;
font-weight: 500;
font-size: size(18);
line-height: size(28);
/* identical to box height, or 156% */
margin-left: size(6);
margin-top: size(10);
letter-spacing: 0.06em;

/* 标题色 */

color: #071325;
  }
  .recomItem{
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 400;
    font-size: size(12);
    line-height: size(28);
    /* identical to box height, or 233% */
    
    letter-spacing: 0.09em;
    
    color: #000000;
    background: #E8EDF1;
border-radius: size(4);
margin: size(5);
padding:0 size(12);
  }

.emptyOppText{
  font-family: 'PingFangSC';
font-style: normal;
font-weight: 400;
font-size: size(14);
line-height: size(28);
/* identical to box height, or 200% */


color: #000000;

opacity: 0.4;
}
  .oppEmptyImgContainer{
//width: 375px;
margin-top: size(30);
// background-color: red;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  }

  .emptyImg{

    height: size(150);
    // width: 86px;
  }

.categories {
  max-width: 100vw;
  margin: size(15);
  padding: 0 size(16);
  display: flex;
  overflow: auto;

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .categoryActive {
    margin-right: size(20);
    padding: size(1) size(9);
    background: #13DDAD;
    border-radius: size(3);
    font-weight: 400;
    font-size: size(14);
    line-height: size(28);
    letter-spacing: 0.09em;
    color: #FFFFFF;
    white-space: nowrap;
  }
  .category {
    margin-right: size(20);
    padding: size(1) size(9);


    
   

    color: #071325;
    white-space: nowrap;

    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 400;
    font-size: size(14);
    line-height: size(28);
    /* identical to box height, or 200% */
    
    letter-spacing: 0.09em;
    
    /* 正文浅灰 */
    
    color: #071325;
    
    opacity: 0.5;
    background: #ECEFF3;
    border-radius: size(3);
  }
}