@use 'src/styles/mobile/colors.scss';
@import '@/styles/mobile/common.scss';

.headerMobile {
  width: 100%;
  height: size(55);
  position: fixed;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: size(11) size(0) size(14) size(0);

  font-size: size(16);
  background-color: #FFF;
  color: colors.$primary;
  z-index: 2;

  .headerMenuNav {
    width: size(24);
    height: size(24);
    margin-left: size(16);
    background-image: url('../../../assets/mobile/header/header_menu_toggle@2x.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  
  .headerLogo {
    width: size(92);
    height: size(30);
    margin-left: size(102);
    margin-right: size(54);
  }
}