.footer {
   margin-bottom: 21px;
   display: flex;
   justify-content: center;
   align-items: center;
   position: fixed;
   z-index: 1;

   // width: 633px;
   width: 100%;
   height: 22px;
   // margin-left: -315.5px;
   // margin-bottom: 21px;
   // left: 50%;
   bottom: 0;

   > p {
   margin-right: 40px;
   font-family: 'PingFangSC';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 22px;
   letter-spacing: 0.09em;
   text-transform: capitalize;

   color: #071325;
   }
}