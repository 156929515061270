@use 'src/styles/web/common';

  .about{
		@extend .Section;
		  padding: 105px 175px 76px 40px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  background-color: #F8F9FD;

		.about-content{
				  height: 679px;
				  width: 1696px;
				  display: flex;
				  flex-direction: column;
  
				  .title-container{
					  height: 76px;
					  width: 100%;
					  display: flex;
					  justify-content: flex-start;
					  
					  .page-title-en{
						  margin-top: 21px;
						  margin-left: 262px;
						  height: 76px;
						  width: 460px;
					  }
  
					  .ch-title-container{
							margin-left: 267px;
						  display: flex;
						  flex-direction: column;
  
						  .page-title-ch{
							  margin: 16px 0px -10px -8px;
							  height: 76px;
							  width: 228px;
						  }
						  p{
							margin: 0px 1px 0px 3px;
							//   font-family: 'PingFang SC';
							  font-style: normal;
							  font-weight: 400;
							  font-size: 30px;
							  line-height: 38px;
							  /* identical to box height, or 127% */
							  text-transform: uppercase;
							  color: #071325;
							  opacity: 0.2;
						  }
					  }
				  }
  
				  .row-container{
					  position: relative;
					  display: flex;
  
					  .toggle-container{
						  position: absolute;
						  top: -19px;
						  right: 75px;
						  height: 40px;
						  width: 86px;
						  // background: linear-gradient(169.13deg, rgba(255, 255, 255, 0.8526) 32.71%, rgba(255, 255, 255, 0.735) 98.44%);
						  border-radius: 9px;
						  z-index: 1;
						  display: flex;
						  align-items: center;
						  justify-content: center;
						  background-image: url('../../../assets/web/About/company_profile_switch_bg@2x.webp');
						  background-size: cover;
  
						  .circle-container{
							  height: 12px;
							  width: 42px;
							  display: flex;
							  justify-content: space-between;
  
							  .item-circle{
								  height: 12px;
								  width: 12px;
								  border-radius: 50%;
								  background: #D5D5D5;
								  border: 2px solid;
								  border-image-source: linear-gradient(173.35deg, rgba(255, 255, 255, 0) 5.22%, #FFFFFF 71.83%);
									cursor: pointer;
							}
  
							//   .circle-inactive{
							// 	  height: 12px;
							// 	  width: 12px;
							// 	  border-radius: 50%;
							// 	  background: #01CA69;
							// 	  border: 2px solid;
							// 	  border-image-source: linear-gradient(173.35deg, rgba(255, 255, 255, 0) 5.22%, #FFFFFF 71.83%);
							//   }
						  }
					  }
  
					  .left-content{
						  height: 100%;
						  width: 591px;
	  
						  .logo-bg{ 
							  margin-top: -25px;
							  height: 650px;
							  width: 650px;
						  }
					  }
	  
					  .right-content{
						  height: 100%;
						  width: 1105px;
	  
						  .home-slider-container{
							  position: relative;
							  padding: 51px 48px 18px 144px;
							  height: 500px;
							  width: 986px;
							  border-radius: 8px;
							  background: linear-gradient(2.54deg, rgba(255, 255, 255, 0) 6.72%, rgba(186, 198, 227, 0.252) 140.78%), linear-gradient(180deg, rgba(248, 249, 253, 0.5382) 0%, rgba(248, 249, 253, 0.78) 100%);
							  backdrop-filter: blur(11.5px);
  
							  .robot{
								  height: 503px;
								  width: 321px;
								  position: absolute;
								  left: -135px;
								  top: 82px;
							  }
							  .slider-wrapper{
								  display: flex;
								  flex-direction: row;
								  gap: 70px;
								  overflow-x: scroll;
  
								  .white-box-about{
									  padding-top: 15px;
									  height: 434px;
									  width: 794px;
									  background-image: url('../../../assets/web/About/about_rectange_bg@2x.webp');
									  background-repeat: no-repeat;
									  background-size: cover;
									//   background: linear-gradient(0deg, rgba(241, 243, 249, 0.38), rgba(241, 243, 249, 0.38)), linear-gradient(169.13deg, rgba(255, 255, 255, 0.8526) 32.71%, rgba(255, 255, 255, 0.735) 98.44%);
									//   backdrop-filter: blur(26.5px);
									  border-radius: 9px;
									  z-index: 1;
									  cursor: pointer;
  
									  .label-bg-ZH{
										  margin-left: -4px;
										  // height: 75px;
										  // width: 153px;
										  z-index: -9;
										  // background-image: url('../../../assets/web/About/label_title_bg@2x.webp');
										  // background-repeat: no-repeat;
										  // background-size: cover;
										  display: flex;
										  align-items: center;
										  border-radius: 0px 34px 39px 0px;
											// background: linear-gradient(270deg, #FFFFFF 15.6%, #F8FCFF 100%);
											background-image: url('../../../assets/web/About/label_title_bg_ZH@2x.webp');
											height: 62px;
											width: 142px;
										  background-size: 100%;
	  
										  .about-title-ZH{
												margin: 9px 31px 9px 31px;
											  width: max-content;
											  white-space: nowrap;
											  // font-family: 'PingFang SC';
											  font-style: normal;
											  font-weight: 400;
											  font-size: 18px;
											  line-height: 30px;
											  word-spacing: 5px;
											  color: #071325;
										  }
									  }

										.label-bg-EN{
										  margin-left: -2px;
										  // height: 75px;
										  // width: 153px;
										  z-index: -9;
										  // background-image: url('../../../assets/web/About/label_title_bg@2x.webp');
										  // background-repeat: no-repeat;
										  // background-size: cover;
										  display: flex;
										  align-items: center;
										  border-radius: 0px 34px 39px 0px;
											// background: linear-gradient(270deg, #FFFFFF 15.6%, #F8FCFF 100%);
											background-image: url('../../../assets/web/About/label_title_bg_EN@2x.webp');
											border: 1px solid #FFFFFF;
											box-shadow: 5px 6px 10px rgba(202, 213, 225, 0.28);
											height: 62px;
											width: 235px;
											background-size: 100%;
	  
										  .about-title-EN{
												margin: 9px 31px 9px 31px;
											  width: max-content;
											  white-space: nowrap;
											  // font-family: 'PingFang SC';
											  font-style: normal;
											  font-weight: 400;
											  font-size: 18px;
											  line-height: 30px;
											  word-spacing: 5px;
											  color: #071325;
										  }
									  }

									  .about-desc{
										  margin: 26px 24px 50px 31px;
										  height: 280px;
										  width: 746px;							
										  // font-family: 'PingFang SC';
										  font-style: normal;
										  font-weight: 400;
										  font-size: 14px;
										  line-height: 28px;
										  /* or 200% */
										  text-align: left;
										  letter-spacing: 0.09em;
										  color: #071325;	
										  opacity: 0.5;
									  }
								  }
							  }
								  .white-box-cards{
									  margin-right: 5px;
									  // padding-top: 15px;
									  height: 434px;
									  width: 794px;
									  // backdrop-filter: blur(26.5px);
									  border-radius: 9px;
									  display: flex;
									  align-items: center;
									  flex-direction: row;
									  z-index: 1;
									  cursor: pointer;
  
									  .cards{
										  height: 436px;
										  width: 243px;
										  margin-right: 26px;
										  background: linear-gradient(169.13deg, rgba(255, 255, 255, 0.6468) 32.71%, rgba(255, 255, 255, 0.735) 98.44%);
										  backdrop-filter: blur(26.5px);
										  /* Note: backdrop-filter has minimal browser support */
										  border-radius: 8px;	
										  display: flex;
										  flex-direction: column;
										  // justify-content: space-around;
										  align-items: center;

										  .strategy-about{
											  margin-top: 38px;
											  margin-bottom: 18px;
											  height: 141px;
											  width: 240px;
											  // background-image: url('../../../assets/web/About/strategy_img@2x.webp');
												background-image: url('../../../assets/web/About/strategy_img_hover@2x.webp');
											  background-size: cover;
											  background-repeat: no-repeat;
										  }
										  // &:nth-child(1):hover { 
											// .strategy-about{
											// 	background-image: url('../../../assets/web/About/strategy_img_hover@2x.webp');
											// }
										  // }
										  .vision-about{
											  margin-top: 34px;
											  margin-bottom: 34px;
											  height: 129px;
											  width: 121px;
											  // background-image: url('../../../assets/web/About/vision_img@2x.webp');
												background-image: url('../../../assets/web/About/vision_img_hover@2x.webp');
											  background-size: cover;
											  background-repeat: no-repeat;
										  }
										  // &:nth-child(2):hover { 
											// .vision-about{
											// 	background-image: url('../../../assets/web/About/vision_img_hover@2x.webp');
											// }
										  // }
  
										  .mission-about{
											  margin-top: 33px;
											  margin-bottom: 35px;
											  height: 129px;
											  width: 121px;
											  // background-image: url('../../../assets/web/About/mission_img@2x.webp');
												background-image: url('../../../assets/web/About/mission_img_hover@2x.webp');
											  background-size: cover;
											  background-repeat: no-repeat;
										  }

										  // &:nth-child(3):hover { 
											// .mission-about{
											// 	background-image: url('../../../assets/web/About/mission_img_hover@2x.webp');
											// }
										  // }
  
										  .card-detail{
											height: 200px;

											  .card-detail-title{
												  font-style: normal;
												  font-weight: 400;
												  font-size: 20px;
												  line-height: 36px;
												  text-align: center;
												  color: #071325;
											  }
											  .card-detail-desc{
												  margin: 0 15px 6px 15px;
												  width: 216px;
												  height: max-content;
												  text-align: left;
												  // font-family: 'PingFang SC';
												  font-style: normal;
												  font-weight: 400;
												  font-size: 14px;
												  line-height: 20px;
												  /* or 143% */
												  color: #071325;
												  opacity: 0.5;
											  }
										  }
									  }
									  .cards:last-child {
										margin-right: 0;
									  }
								  }
  
							  .slider-wrapper::-webkit-scrollbar {
								  width: 0;
							  }
  
							  .globe-img{
								  position: absolute;
								  right: -121px;
								  bottom: -47px;
								  height: 254px;
								  width: 258px;
							  }
						  }
					  }
				  }
		}
  }

	.bullet {
		cursor: pointer;
	}

  .swiper-pagination{
	position: absolute !important;
	left: 916px !important;
    top: -19px !important;
	height: 40px !important;
	width: 86px !important;
	border-radius: 9px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background-image: url('../../../assets/web/About/company profile_switch_bg@2x.webp');
	background-size: cover;
	cursor: default;

	.swiper-pagination-bullet {
		height: 12px;
		width: 12px;
	}

	.swiper-pagination-bullet:nth-child(1) {
		margin-right: -6px !important;
		height: 12px;
		width: 12px;
	}

	.swiper-pagination-bullet-active{
		background-color: #01CA69;
	}
}